<template>
    <div class="content-wrapper overflow-visible">
    <div class="content pt-0">
        <div class="card mb-1">
        <div class="card-body">
            <div class="row  d-flex justify-content-between">
            <div class="col-md-6">
                <h6 class="card-title mb-0">Status:<span class="badge badge-warning ml-1" v-if="row.ar_status == 1 && row.ar_process_status == 'QUEUE'">Menunggu</span>
                <span class="badge badge-purple ml-1" v-if="row.ar_is_fast_track == 'Y'">FAST TRACK</span>
                </h6>
                <p class="mb-0 mt-1"><span class="text-success font-weight-semibold">{{row.ap_gol_usia||"-"}}</span><span v-if="row.arres_is_alat_bantu == 'Y'">, Menggunakan Alat
                Bantu ({{getConfigDynamic(mAlatBantu,row.arres_alat_bantu)||"-"}})</span></p>
            </div>
            <div class="col-md-6">
                <router-link class="btn float-right btn-primary btn-labeled btn-labeled-left" :to="{name: $route.name, params: {pageSlug: row.arres_id}}">
                  <b><i class="icon-pencil7"></i></b>
                  Edit Data
                </router-link>

                <a href="javascript:;" @click="openVerif = true" v-if="row.ar_is_verif_admisi !== 'Y'" data-popup="tooltip" title="Verifikasi Data" data-toggle="modal"
                class="btn float-right mr-1 btn-success btn-labeled btn-labeled-left">
                <b><i class="icon-check"></i></b>
                Verifikasi Data
                </a>
            </div>
            </div>
        </div>
        </div>

        <div class="card mb-1">
        <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Data Reservasi</h6>
        </div>
        <div class="card-body">
            <div class="row">
            <div class="col-md-auto">
                <div class="wrap_qrcode">
                <img :src="$parent.uploader(row.ar_qr_code)" class="img-fluid" />
                </div>
            </div>
            <div class="col-md-7">
                <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{row.ap_code||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Cara Bayar</h4>
                        <span>{{getConfigDynamic(Config.mr.statusJaminan,row.arres_jaminan)||"-"}}</span>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Pemeriksaan </h4>
                        <span>{{getConfigDynamic(Config.mr.statusPemeriksaanOpt,row.arres_type)||"-"}}</span>
                        <p class="text-info d-block mb-0" v-if="row.arres_type=='Poli'">
                        {{getConfigDynamic(mPoli,row.arres_poli)||"-"}}</p>
                        <p class="text-info d-block mb-0" v-else-if="row.arres_type=='Penunjang'">
                        {{getConfigDynamic(Conifg.mr.statusPenunjangOpt,row.arres_jenis_penunjang)||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Tanggal, Jam Periksa</h4>
                        <p>{{row.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Diperiksa Oleh</h4>
                        <p>{{getConfigDynamic(mDokter, row.arres_dokter)||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Pasien Kecelakaan</h4>
                        <p>{{row.arres_is_kecelakaan == 'Y'?'Ya':'Tidak'}}</p>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="col-md-auto">
                <div class="queue_wrap">
                <p>Nomor Antrian</p>
                <h3>{{row.ar_no_antrian||"-"}}</h3>
                <h4>{{getConfigDynamic(mPoli, row.ar_mpo_id)||"-"}}</h4>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="card">
        <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
                :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabPJ"
                :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabKW"
                :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabKW"
                :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Dokumen</a></li>
        </ul>
        <div class="tab-content">
            <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasien">
                <div class="card-body">
                <table class="table table-bordered table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{rowPasien.ap_code||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Nama</h4>
                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>NIK</h4>
                            <p>{{rowPasien.ap_nik}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Tempat, Tanggal Lahir</h4>
                            <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMM YYYY")}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Usia</h4>
                            <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Kategori Usia</h4>
                            <p>{{rowPasien.ap_gol_usia||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p>{{rowPasien.cg_label||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Agama</h4>
                            <p v-if="rowPasien.ma_id !== 99999">{{rowPasien.ma_name||"-"}}</p>
                            <p v-else>{{rowPasien.ap_agama_text||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Suku</h4>
                            <p>{{rowPasien.mrsuk_name||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Pekerjaan</h4>
                            <p v-if="rowPasien.mp_id !== 99999">{{rowPasien.mp_name||"-"}}</p>
                            <p v-else>{{rowPasien.ap_pekerjaan_text||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Pendidikan</h4>
                            <p>{{rowPasien.mpen_name||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Nama Ibu Kandung</h4>
                            <p>{{rowPasien.ap_nama_ibu||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>No. Telpon</h4>
                            <p>{{rowPasien.ap_phone_number||"-"}}</p>
                        </div>
                        </td>
                        <td colspan="2">
                        <div class="result_tab">
                            <h4>Email</h4>
                            <p v-if="rowPasien.ap_email">{{rowPasien.ap_email.trim()||"-"}}</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Status Pernikahan</h4>
                            <p v-if="rowPasien.ap_status_pernikahan == 1">Belum Kawin</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 2">Kawin</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 3">Cerai Hidup</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 4">Cerai Mati</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Bahasa yang Dikuasai</h4>
                            <p>{{rowPasien.ap_language||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Golongan Darah</h4>
                            <p v-if="rowPasien.ap_gol_darah">{{rowPasien.ap_gol_darah.trim()||"-"}}</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                        <div class="result_tab">
                            <h4>Alamat Domisili</h4>
                            <p>{{domisiliAddr||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                        <div class="result_tab">
                            <h4>Alamat Sesuai KTP</h4>
                            <p>{{ktpAddr||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="penanggung-jawab">
                <div class="card-body">
                    <table class="table table-borderless table-sm text-uppercase">
                        <tbody>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Hubungan Keluarga</h4>
                                <p>{{rowPasien.arpj_hubungan_keluarga_text||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Nama Penanggung Jawab</h4>
                                <p>{{rowPasien.arpj_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Pekerjaan </h4>
                                <p v-if="rowPasien.arpj_pekerjaan !== 99999">{{rowPasien.pekerjaan_pj||"-"}}</p>
                                <p v-else>{{rowPasien.arpj_pekerjaan_text||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{rowPasien.gender_pj||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Agama </h4>
                                <p v-if="rowPasien.arpj_agama !== 99999">{{rowPasien.agama_pj||"-"}}</p>
                                <p v-else>{{rowPasien.arpj_agama_text||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>No Handphone</h4>
                                <p>+62{{rowPasien.arpj_phone_number}}</p>
                            </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                            <div class="result_tab">
                                <h4>Alamat Domisili</h4>
                                <p>{{pjAddr||"-"}}</p>
                            </div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="informasi-kewenangan">
                <div class="card-body">
                    <table class="table table-borderless table-sm text-uppercase">
                        <tbody>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Hubungan Keluarga</h4>
                                <p>{{rowPasien.arki_hubungan_keluarga_text||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Nama Penanggung Jawab</h4>
                                <p>{{rowPasien.arki_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Pekerjaan </h4>
                                <p v-if="rowPasien.arki_pekerjaan !== 99999">{{rowPasien.pekerjaan_ki||"-"}}</p>
                                <p v-else>{{rowPasien.arki_pekerjaan_text||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{rowPasien.gender_ki||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Agama </h4>
                                <p v-if="rowPasien.arki_agama !== 99999">{{rowPasien.agama_ki||"-"}}</p>
                                <p v-else>{{rowPasien.arki_agama_text||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>No Handphone</h4>
                                <p>+62{{rowPasien.arki_phone_number}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="dokumen">
            <div class="card-body">
                <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                    <tr v-if="row.arres_jaminan != 3">
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto KTP/KK</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_file_ktp)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Pasien</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_file_foto_pasien)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%" v-if="row.arres_jaminan == 2">
                        <div class="result_tab">
                        <h4>Foto Kartu Asuransi</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_file_asuransi)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>
                    <tr v-else>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Kartu BPJS</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_kartu_bpjs)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Surat Rujukan</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_surat_rujukan)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Surat Kontrol</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div>
                            <a :href="$parent.uploader(row.arres_surat_kontrol)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                        </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>
        </div>
        <!-- /dashboard content -->
    </div>

    <b-modal id="formMedicalRecord" v-model="openVerif" size="md" hide-header hide-footer>
        <div>
            <div class="modal-header">
                <h5 class="modal-title">Verifikasi Reservasi Pasien</h5>
                <button type="button" class="close" data-dismiss="modal">×</button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-check"></i>
                </div>
                <h6 class="mb-0">
                    Apakah Anda yakin untuk memverifikasi reservasi pasien atas nama
                </h6>
                <h3 class="font-weight-semibold text-primary">{{row.ap_fullname||"-"}}</h3>
                <div>
                    <a href="javascript:;" @click="verif('Y')" class="mr-1 btn btn-success btn-labeled btn-labeled-left">
                    <b><i class="icon-checkmark"></i></b>
                    Konfirmasi
                    </a>
                    <a href="javascript:;" @click="verif('N')" data-dismiss="modal" class="mr-1 btn btn-danger btn-labeled btn-labeled-left">
                    <b><i class="icon-blocked"></i></b>
                    VOID
                    </a>
                    <a href="javascript:;" @click="openVerif = false" data-dismiss="modal" class="mr-1 btn btn-outline-secondary">
                    Cancel
                    </a>
                </div>
                </div>
            </div>
        </div>
    </b-modal>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Config from '@/libs/Config'

export default{
    props:{
        row:Object,
        mrValidation:Object
    },
    data(){
        return {
            mPasien: [],
            mAsuransi: [],
            mWaktuPeriksa: [],
            mPoli: [],
            mAlatBantu: [],
            mDokter: [],
            activeTab: 1,
            rowPasien: {},
            openVerif: false
        }
    },
    methods: {
        back(){
            this.$router.back()
        },
        verif(isVerif){ 
            let data ={
                isverif : isVerif,
                type: 'verif-data-pasien',
                ar_id: this.row.ar_id
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.openVerif = false
                this.$swal({
                    title: `Data Berhasil Diverifikasi`,
                    icon: 'success',
                })
                this.$parent.apiGet()
            })
        },
        apiGetMaster(){
            let data = {
                poli_id : this.row.arres_poli
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = master.findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        changeTab(e){
            this.activeTab = e
        },
        apiGetPasien(){
            let data = {
                poli_id : this.row.arres_poli
            }
            Gen.apiRest(
                "/get/"+"Pasien"+'/'+this.row.arres_ap_id, 
                {
                    params: data
                }
            ).then(res=>{
                this.rowPasien = res.data.row
            })
        },
    },
    computed: {    
        Config(){ return Config },
        ktpAddr(){
        if(this.rowPasien.ap_address && this.rowPasien.ap_rt && this.rowPasien.ap_rw && this.rowPasien.ap_kelurahan && this.rowPasien.ap_kecamatan && this.rowPasien.ap_kota){
            return (this.rowPasien.ap_address||"-") + "RT."+(this.rowPasien.ap_rt||"-")+"/"+(this.rowPasien.ap_rw||"-")+", DESA "+
            (this.rowPasien.ap_kelurahan||"-")+", KEC."+(this.rowPasien.ap_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_kota||"-")
        }else{
            return "-"
        }
        },
        domisiliAddr(){
        if(this.rowPasien.ap_dom_address && this.rowPasien.ap_dom_rt && this.rowPasien.ap_dom_rw && this.rowPasien.ap_dom_kelurahan && this.rowPasien.ap_dom_kecamatan && this.rowPasien.ap_dom_kota){
            return (this.rowPasien.ap_dom_address||"-") + "RT."+(this.rowPasien.ap_dom_rt||"-")+"/"+(this.rowPasien.ap_dom_rw||"-")+", DESA "+
            (this.rowPasien.ap_dom_kelurahan||"-")+", KEC."+(this.rowPasien.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
        }else{
            return "-"
        }
        },
        pjAddr(){
        if(this.rowPasien.arpj_address && this.rowPasien.arpj_rt && this.rowPasien.arpj_rw && this.rowPasien.arpj_kelurahan && this.rowPasien.arpj_kecamatan && this.rowPasien.arpj_kota){
            return (this.rowPasien.arpj_address||"-") + "RT."+(this.rowPasien.arpj_rt||"-")+"/"+(this.rowPasien.arpj_rw||"-")+", DESA "+
            (this.rowPasien.arpj_kelurahan||"-")+", KEC."+(this.rowPasien.arpj_kecamatan||"-")+ "KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
        }else{
            return "-"
        }
        },
    },
    mounted() {
        setTimeout(()=>{
            this.apiGetMaster()
            this.apiGetPasien()
        },1500)
    },
}

</script>