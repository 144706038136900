<template>
    <div class="content-wrapper">
        <bo-page-title>
            <template v-if="isList" slot="additionalCta">
                <b-button @click="addNewReservasi" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                    <b><i class="icon-plus2"></i></b> Buat Reservasi Baru
                </b-button>
            </template>
        </bo-page-title>

        <div v-if="isList" class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-auto col-lg-7">
                            <div class="form-row justify-content-end">
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_red">
                                        <h3>
                                            <i class="icon-users2"></i>
                                            {{pasienBaru}} PASIEN BARU
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_green">
                                        <h3>
                                            <i class="icon-user-check"></i>
                                            {{pasienLama}} PASIEN LAMA
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-md-auto">
                                    <a href="#" data-toggle="modal" data-target="#FunnelData"
                                        class="lbl_status_pasien bg_blue">
                                        <h3><i class="icon-menu"></i></h3>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-7 col-lg-8">
                            <div class="row gx-1">
                                <div class="col-md">
                                    <div class="row gx-1">
                                        <div class="col-md-4">                                    
                                            <b-form-group class="mb-0">
                                                <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status_pasien"  :options="Config.mr.statusPasien" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder="Pilih Pemeriksaan" @input="doFill" v-model="filter.status_periksa"  :options="Config.mr.statusPemeriksaan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group class="mb-0">
                                                <v-select placeholder="Pilih Jaminan" @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                <input class="form-control"
                                    v-model="filter.search"
                                    @input="doSearch"
                                    placeholder="Ketik Nama/No. RM Pasien"
                                />
                                <div class="form-control-feedback">
                                    <i class="icon-search4 text-indigo"></i>
                                </div>
                                    
                                <b-button
                                    class="ml-1 d-inline-flex align-items-center"
                                    variant="outline-success"
                                    id="resetBtn"
                                    @click="doResetData()"
                                >Reset</b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>Antrian</th>
                                <th>Nama</th>
                                <th>No RM</th>
                                <th>Pemeriksaan</th>
                                <th>Waktu Periksa</th>
                                <th>Cara Bayar</th>
                                <th>Status</th>
                                <th width="120">Aksi</th>
                            </tr>
                        </thead>
                        <tbody v-if="(dataList||[]).length">
                            <tr v-for="(v,k) in (dataList||[])" :key="k">
                                <td>
                                    <div class="text-success-700 font-weight-semibold">{{v.ar_reg_code||"-"}}</div>
                                    <small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
                                </td>
                                <td>
                                    <div>{{v.ap_fullname||"-"}}</div>
                                    <div class="text-info">{{v.ap_nik||"-"}}</div>
                                <td> 
                                    <template v-if="v.ap_is_old_pasien">
                                        <span class="text-info" v-if="v.ap_is_old_pasien !== 'Y'">PASIEN BARU</span>
                                        <span class="text-danger" v-else>PASIEN LAMA</span>
                                    </template>
                                    <template v-else>
                                        <span> - </span>
                                    </template>                              
                                    <div class="font-weight-semibold">{{v.ap_nik||"-"}}</div>
                                </td>
                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusPemeriksaan,v.arres_type)||"-"}}</div>
                                </td>
                                <td>
                                    <span v-if="v.ar_reg_date">{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</span>
                                    <span v-else> - </span>
                                </td>
                                <td>
                                    <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.arres_jaminan)||"-"}}</div>
                                </td>
                                <td>
                                    <template v-if="v.arres_is_draft !== 'Y'">
                                        <span class="badge badge-warning mb-1" v-if="v.ar_status == 1 && v.ar_process_status == 'QUEUE'">Menunggu </span>
                                        <span class="badge badge-success mb-1" v-else-if="v.ar_status == 7">Selesai Periksa</span>
                                        <span class="badge badge-info mb-1" v-else> Dalam Tindakan </span>
                                    </template>

                                    <span class="badge bg-primary mb-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>
                                    <span class="badge bg-warning mb-1" v-if="v.arres_is_draft == 'Y'">Draft</span>
                                    <span class="badge bg-warning" v-if="v.arres_is_draft !== 'Y' && v.ar_is_verif_admisi !== 'Y'">Perlu Verifikasi</span>
                                    
                                </td>
                                <td>
                                    <div class="d-flex">
                                    <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                    v-if="moduleRole('lihat_detail') && v.arres_is_draft !== 'Y'"
                                    :to="{name: $route.name, params: {pageSlug: v.arres_id}, query: {isDetail: true}}"
                                    data-toggle="tooltip" data-placement="top" title="View"><i class="icon-eye"></i></router-link>

                                    <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                    :to="{name: $route.name, params: {pageSlug: v.arres_id}}"
                                    data-toggle="tooltip" data-placement="top" title="Edit Data"><i class="icon-pencil5"></i></router-link> 
                                    
                                    <a href="javascript:;" v-if="v.arres_is_draft == 'Y'" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                    @click="doDelete(k, v)"
                                    :to="{name: $route.name, params: {pageSlug: v.arres_id}}"
                                    data-toggle="tooltip" data-placement="top" title="Hapus Data"><i class="icon-bin"></i></a> 

                                    <a href="javascript:;" v-if="v.ar_is_fast_track !== 'Y' && v.arres_is_draft !== 'Y'" data-toggle="modal" @click="doFastTrack(v)" data-target="#Fasttrack" data-popup="tooltip" title="Pasien Fast Track" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Pasien Fast Track"><i class="icon-list-numbered"></i></a>

                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="data.data.length == 0">
                            <tr>
                                <td colspan="99">
                                <div class="text-center">
                                    <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-if="!data.data">   
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                            <tr>
                                <td colspan="99"><div class="skeletal-comp"></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
                <b-pagination
                    class="mb-0"
                    v-model="pageNo"
                    :per-page="data.per_page"
                    :total-rows="data.total"
                />
                </b-card-footer>
            </div>
        </div>
            
        <template v-else>
            <FormDetail v-if="isDetail" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>

		<validation-observer ref="VFormFastTrack">
        <b-modal v-model="openModalFastTrack" :title="'Indikasi Fast Track'" size="sm" @ok.prevent="submitFastTrack">
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Indikasi Fast Track <small class="txt_mandatory">*</small></label>
                    <b-form-radio-group
                        :options="Config.mr.mFastTrack"
                        v-model="rowEdit.arres_is_alat_bantu"
                    />
                    <VValidate 
                        name="Indikasi Fast Track" 
                        v-model="rowEdit.arres_is_alat_bantu" 
                        :rules="{required: 1}"
                    />
                </div>

                <template v-if="rowEdit.arres_is_alat_bantu">
                    <div class="col-12" id="Formalatbantu" v-if="rowEdit.arres_is_alat_bantu == 'Y'">
                        <div class="form-group">
                        <label>Alat Bantu<small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Pilih Alat Bantu" v-model="rowEdit.arres_alat_bantu"  :options="mAlatBantu" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Alat Bantu" 
                            v-model="rowEdit.arres_alat_bantu" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="col-12" id="FormFtLainnya" v-else>
                        <div class="form-group">
                        <textarea v-model="rowEdit.ar_fast_track_notes" name="ftNote" id="ftNote" rows="4" class="form-control"
                            placeholder="e.g. kondisi pasien sudah urgent"></textarea>
                        </div>
                        <VValidate 
                            name="Catatan Fast Track" 
                            v-model="rowEdit.ar_fast_track_notes" 
                            :rules="{required: 1}"
                        />
                    </div>
                </template>
            </div>
        </b-modal>
        </validation-observer>
    </div>
</template>


<script>

    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')

    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'
    import Form from './Form.vue'
    import FormDetail from './FormDetail.vue'

    export default {
        extends: GlobalVue,
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien
                }
            },
        },
        data(){
            return {
                idKey:'arres_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,
                openModalFastTrack : false,
                rowEdit : {},
                mAlatBantu: [],
                rowPasien : {}
            }
        },
  	    components:{DateRangePicker,Form,FormDetail},
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'
            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetResume()
            }
        },
        methods: {
            doFastTrack(row){
                this.rowEdit = row
                this.openModalFastTrack = true
            },
            submitFastTrack(){
                this.$refs['VFormFastTrack'].validate().then(success=>{
                    if(!success) return
                    this.rowEdit.type = 'update-fastrack'
                    if(this.rowEdit.arres_is_alat_bantu == 'Y'){
                        this.rowEdit.ar_fast_track_notes = null
                    }else{
                        this.rowEdit.arres_alat_bantu = null
                    }
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: this.rowEdit}, 
                        "POST"
                    ).then(res=>{
                        this.$swal({
                            title: `Berhasil`,
                            text: 'Berhasil Mengubah Data Pasien',
                            icon: 'success',
                        })
                        this.apiGet()
                        this.openModalFastTrack = false
                    })
                })
            },
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            addNewReservasi() {
                let data = {
                    type : 'init-new-reservasi'
                }
                this.loadingOverlay = true

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    let data = res.data.data
                    this.$router.push({ name: 'RoReservasi', params: { pageSlug: data.arres_id } })
                    .catch(()=>{})
                })
            },
            toDetail(data){
                this.$router.push({ name: 'RoReservasi', params: { pageSlug: data.arres_id } })
                .catch(()=>{})
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.doPageOne()
                this.doFilter()
            },100),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = master.findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(){
                this.apiGet()
                if(this.isList){
                    this.apiGetResume()
                }
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }

    }
</script>