<template>
    <fieldset>
        <div class="resume-ticket mb-4">
        <div class="history_card">
            <div class="history_card_body">
            <div class="row g-2">
                
                <div class="col-6">
                <div class="history_info">
                    <h3>Nama</h3>
                    <span>{{isParent.row.ap_fullname||"-"}}</span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>Cara Bayar</h3>
                    <span>{{getConfigDynamic(Config.mr.statusJaminan,isParent.row.arres_jaminan)||"-"}}</span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>Asuransi</h3>
                    <span v-if="isParent.row.arres_jaminan == 2">{{getConfigDynamic(isParent.mAsuransi,isParent.row.arres_poli)||"-"}}</span>
                    <span v-else> - </span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>Alamat</h3>
                    <span>{{addr||"-"}}</span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>No Handphone</h3>
                    <span v-if="isParent.row.ap_phone_number">+{{isParent.row.ap_phone_number||"-"}}</span>
                    <span v-else> - </span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>Status</h3>
                    <span class="badge badge-info">Terjadwal</span>
                </div>
                </div>
                <div class="col-6">
                <div class="history_info">
                    <h3>Pemeriksaan</h3>
                    <span>{{getConfigDynamic(Config.mr.statusPemeriksaanOpt,isParent.row.arres_type)||"-"}}</span>
                    <p class="text-info d-block mb-0" v-if="isParent.row.arres_type=='Poli'">
                    {{getConfigDynamic(isParent.mPoli,isParent.row.arres_poli)||"-"}}</p>
                    <p class="text-info d-block mb-0" v-else-if="isParent.row.arres_type=='Penunjang'">
                    {{getConfigDynamic(statusPenunjangOpt,isParent.row.arres_jenis_penunjang)||"-"}}
                    </p>
                </div>
                </div>


                <div class="col-6">
                <div class="history_info">
                    <h3>Est. Tanggal/Jam Periksa</h3>
                    <span>{{isParent.row.arres_waktu_periksa||"-"}}</span>
                </div>
                </div>
            </div>
            </div>
            <div class="history_card_body">
            <div class="row g-2">

                <div class="col-12">
                <div class="history_info">
                    <h3>Diperiksa oleh</h3>
                    <span>{{getConfigDynamic(isParent.mDokter,isParent.row.arres_jadwal_dokter_id)||"-"}}</span>
                </div>
                </div>

            </div>
            </div>
        </div>
        </div>
    </fieldset>
</template>

<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
const moment = require('moment')
export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        addr(){
            if(this.isParent.row.ap_dom_address && this.isParent.row.ap_dom_rt && this.isParent.row.ap_dom_rw && this.isParent.row.ap_dom_kelurahan && this.isParent.row.ap_dom_kecamatan && this.isParent.row.ap_dom_kota){
                return (this.isParent.row.ap_dom_address||"-") + "RT."+(this.isParent.row.ap_dom_rt||"-")+"/"+(this.isParent.row.ap_dom_rw||"-")+", DESA "+
                (this.isParent.row.ap_dom_kelurahan||"-")+", KEC."+(this.isParent.row.ap_dom_kecamatan||"-")+ "KAB/KOTA"+(this.isParent.row.ap_dom_kota||"-")
            }else{
                return "-"
            }
        },
    },
    data(){
        return {
            statusPenunjangOpt: [{
                    text: 'Laboratorium',
                    value: 'LAB'
                },
                {
                    text: 'Radiologi',
                    value: 'RADIO'
                },
                {
                    text: 'Fisioterapi',
                    value: 'FISIO'
                },
            ],
        }
    },
    methods: {
        getConfigDynamic(master, value, field='text') {
            let text = ''
            if (value) {
            let index = master.findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index][field]
            }
            }
            return text
        },
    },
}
</script>
